"use client";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import useClientContext from "@/context/ClientContext";
import PublicIcons from "@/components/basic/PublicIcon";
import { IApiCategory } from "@/types/interfaces/categories";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import useViewport from "@/hooks/useViewport";
import useDisableScroll from "@/hooks/useDisableScroll";
import Checkbox from "@/controls/Checkbox";
import { deepCopyObject } from "@/utils/data";
import Button from "@/controls/Button";
import ToggleSwitch from "@/controls/ToggleSwitch";
import useBackdrop from "@/libs/backdrop/useBackdrop";
import { Params, getFilterParams, getInitValuesFromSearch } from "../filters/PanelCategoryFilters";
import { useDataPublicCategoryFilters } from "../../hooks/useData";
import FilterPriceRange, { FilterPriceRangeValue } from "../filters/basic/FilterPriceRange";
import { ICategoryFilters } from "../../pages/api/category-filters.api";
import { useCategoryContext } from "../PageProductsListContext";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";

interface MobileCategoryFiltersProps {
  category?: IApiCategory | null;
  showDinamicFilters?: boolean;
  showBrands?: boolean;
  searchParams: { [param: string]: string };
  mappedCategories: IApiCategoryMapped[];
}

const MobileCategoryFilters = (props: MobileCategoryFiltersProps) => {
  const { category, showDinamicFilters, showBrands, searchParams } = props;
  const { isMobileSize, translate } = useClientContext();

  const { q, sortby, min_price, properties, ...search } = searchParams || {};
  const quantity = Object.keys(search).length + ((properties || "").match(/\:/g) || []).length;

  const refModal = useRef(null);
  const { Backdrop, onHide, onShow, show } = useBackdrop({ elem: refModal.current, hideWhenResizing: false });
  useDisableScroll(show);

  // Hide when resizing to larger
  useEffect(() => {
    if (!isMobileSize && show) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSize]);

  if (!isMobileSize) {
    return null;
  }

  return (
    <div className={styles.MobileCategoryFilters}>
      <div ref={refModal} className={styles.button} onClick={onShow}>
        <PublicIcons name="filter" width={16} height={16} />
        <div>
          {translate("Filters")} {!!quantity && <span className={styles.quantity}>{quantity}</span>}
        </div>
      </div>
      <Backdrop className={styles.content}>
        <MobileFilterContextProvider
          category={category}
          showDinamicFilters={showDinamicFilters}
          searchParams={searchParams}
        >
          <MobileModalCategoryFilters
            showBrands={showBrands}
            show={show}
            onShow={(val) => (val ? onShow() : onHide())}
          />
        </MobileFilterContextProvider>
      </Backdrop>
    </div>
  );
};

export default MobileCategoryFilters;

interface FilterListItem {
  type: "price" | "brands" | "property" | "in_stock" | "sale";
  id?: string;
  label: string;
  values: { id: number; title: string; measurable_value?: string | null }[];
  selected: string[];
  checked?: boolean;
  placeholder?: string;
}

const mobileFilterContextDefault: {
  categoryFiltersList: ICategoryFilters;
  initValues: Params;
  filterIdx: number | null;
  setFilterIdx: (idx: number | null) => void;
  filters: FilterListItem[];
  draftPrice: React.MutableRefObject<FilterPriceRangeValue>;
  onCheckedDraft: (checked: boolean, idx: number, valueId: string, filter: FilterListItem) => void;
  onApplyDraft: () => void;
  onToggleDraft: (type: "in_stock" | "sale", active: boolean) => void;
  onResetDraft: () => void;
  onResetFilters: () => void;
  onShowProducts: () => void;
  onCleanFilters: () => void;
} = {
  categoryFiltersList: {
    brands: [],
    categories: [],
    category_ancestors: [],
    max_price: null,
    min_price: null,
    properties: [],
    status: 200,
    statusText: "default",
    stores: [],
    url: "",
  },
  initValues: {
    free_delivery: false,
    in_stock: false,
    sale: false,
  },
  filterIdx: null,
  setFilterIdx: (idx) => {},
  filters: [],
  draftPrice: { current: {} },
  onCheckedDraft: () => {},
  onToggleDraft: (type: "in_stock" | "sale", active: boolean) => {},
  onApplyDraft: () => {},
  onResetDraft: () => {},
  onResetFilters: () => {},
  onShowProducts: () => {},
  onCleanFilters: () => {},
};

const MobileFilterContext = React.createContext(mobileFilterContextDefault);

const useMobileFilterContext = () => useContext(MobileFilterContext);

interface MobileFilterContextProviderProps {
  category?: IApiCategory | null;
  showDinamicFilters?: boolean;
  searchParams: { [param: string]: string };
  children: any;
}

const MobileFilterContextProvider = (props: MobileFilterContextProviderProps) => {
  const { children, category, showDinamicFilters, searchParams } = props;
  const { category: categoryId, sale } = searchParams;
  const { translate } = useClientContext();
  const { onChangeFilter, onResetFilter } = useCategoryContext();

  const search = searchParams?.[QUERY_SEARCH_PARAM_NAME];
  const { data: categoryFiltersList } = useDataPublicCategoryFilters(categoryId || category?.id, undefined, search, sale ? "1" : undefined);
  const initValues = useMemo(() => getInitValuesFromSearch(searchParams), [searchParams]);
  const [values, setValues] = useState(initValues);
  const [draftValues, setDarftValues] = useState(initValues);

  const filters = useMemo(() => {
    const last = (category && !category?.child.length) || showDinamicFilters;
    const arr: FilterListItem[] = [];

    arr.push({
      type: "price",
      label: translate("Price"),
      values: [],
      selected: [],
    });

    if (categoryFiltersList.brands.length) {
      arr.push({
        type: "brands",
        label: translate("Brand"),
        values: categoryFiltersList.brands.map((i) => ({ id: i.id, title: i.name })),
        selected: draftValues.brands || [],
        placeholder: translate("All brands"),
      });
    }

    if (last) {
      categoryFiltersList.properties.forEach((p) => {
        if (p.property_values.length) {
          arr.push({
            type: "property",
            id: `${p.id}`,
            label: translate(p.name),
            values: p.property_values.map((i) => ({ id: i.id, title: i.title, measurable_value: i.measurable_value })),
            selected: draftValues.propsChecked?.[p.id] || [],
            placeholder: translate("All options"),
          });
        }
      });
    }

    // arr.push({
    //   type: "in_stock",
    //   label: translate("In stock"),
    //   values: [],
    //   selected: [],
    //   checked: draftValues.in_stock,
    // });

    arr.push({
      type: "sale",
      label: translate("Sale"),
      values: [],
      selected: [],
      checked: draftValues.sale,
    });

    return arr;
  }, [
    category,
    showDinamicFilters,
    translate,
    // draftValues.in_stock,
    draftValues.sale,
    draftValues.brands,
    draftValues.propsChecked,
    categoryFiltersList.brands,
    categoryFiltersList.properties,
  ]);

  const onCheckedDraft = useCallback(
    (checked: boolean, idx: number, valueId: string, filter: FilterListItem) => {
      const newDraftValues = deepCopyObject(draftValues) as Params;
      const newSelected = checked
        ? [...filters[idx].selected, valueId]
        : filters[idx].selected.filter((id) => id !== valueId);

      if (filter.type === "brands") {
        newDraftValues.brands = newSelected;
      } else if (filter.type === "property") {
        if (newDraftValues.propsChecked && filter.id) {
          newDraftValues.propsChecked[filter.id] = newSelected;
        }
      }

      setDarftValues(newDraftValues);
    },
    [filters, draftValues]
  );

  const onApplyDraft = useCallback(
    (forceValues?: any) => {
      setValues(forceValues || draftValues);
    },
    [draftValues]
  );

  const onToggleDraft = useCallback(
    (type: "in_stock" | "sale", active: boolean) => {
      const newDraftValues = deepCopyObject(draftValues) as Params;
      // @ts-ignore
      newDraftValues[type] = active ? true : undefined;
      console.log("newDraftValues", newDraftValues);
      setDarftValues(newDraftValues);
      onApplyDraft(newDraftValues);
    },
    [draftValues, onApplyDraft]
  );

  const onResetDraft = useCallback(() => {
    setDarftValues(values);
  }, [values]);

  const [filterIdx, setFilterIdx] = useState<number | null>(null);
  const draftPrice = useRef<FilterPriceRangeValue>(initValues.price || {});

  const onShowProducts = useCallback(() => {
    onChangeFilter(getFilterParams({ ...values, price: draftPrice.current }));
  }, [onChangeFilter, values]);

  const onResetFilters = useCallback(
    (force?: boolean) => {
      draftPrice.current = force ? {} : initValues.price || {};
      setValues(force ? {} : initValues);
      setDarftValues(force ? {} : initValues);
    },
    [initValues]
  );

  const onCleanFilters = useCallback(() => {
    onResetFilters(true);
    onChangeFilter({});
  }, [onChangeFilter, onResetFilters]);

  const contextValue = useMemo(
    () => ({
      categoryFiltersList,
      initValues,
      filters,
      filterIdx,
      setFilterIdx,
      draftPrice,
      onCheckedDraft,
      onApplyDraft,
      onToggleDraft,
      onResetDraft,
      onResetFilters,
      onShowProducts,
      onCleanFilters,
    }),
    [
      categoryFiltersList,
      initValues,
      filters,
      filterIdx,
      onCheckedDraft,
      onApplyDraft,
      onToggleDraft,
      onResetDraft,
      onResetFilters,
      onShowProducts,
      onCleanFilters,
    ]
  );

  return <MobileFilterContext.Provider value={contextValue}>{children}</MobileFilterContext.Provider>;
};

interface MobileModalCategoryFiltersProps {
  showBrands?: boolean;
  show: boolean;
  onShow: (show: boolean) => void;
}

const MobileModalCategoryFilters = (props: MobileModalCategoryFiltersProps) => {
  const { showBrands, onShow } = props;
  const { translate } = useClientContext();
  const viewport = useViewport();

  const {
    filters,
    filterIdx,
    initValues,
    setFilterIdx,
    onCheckedDraft,
    onApplyDraft,
    onResetDraft,
    onResetFilters,
    onShowProducts,
    onCleanFilters,
  } = useMobileFilterContext();
console.log("filters", filters);
  const filter = filterIdx !== null ? filters[filterIdx] : null;

  const refHeader = useRef<HTMLDivElement>(null);

  const quantityActiveFilters = useMemo(() => filters.filter((i) => i.selected.length).length, [filters]);

  const modalTitle = useMemo(() => {
    if (filter) {
      if (!filter.selected.length) {
        return filter.label;
      }

      return (
        <span>
          {filter.label} <span className={styles.quantity}>({filter.selected.length})</span>
        </span>
      );
    }

    if (quantityActiveFilters) {
      return (
        <span>
          {translate("Filters")} <span className={styles.quantity}>({quantityActiveFilters})</span>
        </span>
      );
    }

    return translate("Filters");
  }, [filter, quantityActiveFilters, translate]);

  const scrollToTop = useCallback(() => {
    refHeader.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const onCloseModal = useCallback(() => {
    onShow(false);
    onResetFilters();
    scrollToTop();
  }, [onResetFilters, scrollToTop, onShow]);

  return (
    <div className={styles.MobileModalCategoryFilters} style={{ maxHeight: viewport?.height }}>
      <div ref={refHeader} className={styles.modalTitle}>
        {filterIdx !== null ? (
          <PublicIcons
            name="arrowShortLeft"
            width={24}
            height={24}
            onClick={() => {
              if (filterIdx !== null) {
                setFilterIdx(null);
                onResetDraft();
              }
            }}
          />
        ) : (
          <div />
        )}
        <div>{modalTitle}</div>
        <PublicIcons name="close2" width={24} height={24} onClick={onCloseModal} />
      </div>

      {filterIdx === null ? (
        <>
          <div className={styles.wrapContent} data-scrollable="1">
            {filters.map((i, idx) => {
              if (i.type === "brands" && !showBrands) {
                return null;
              }

              if (i.type === "price") {
                return (
                  <React.Fragment key="price">
                    <MobileFilterPrice />
                    <div className={styles.div} />
                  </React.Fragment>
                );
              }

              if (i.type === "in_stock" || i.type === "sale") {
                return (
                  <React.Fragment key={i.type}>
                    <MobileFilterToggle label={i.label} name={i.type} value={i.checked} />
                    <div className={styles.div} />
                  </React.Fragment>
                );
              }

              const vals = i.values.filter((v) => i.selected.includes(`${v.id}`));

              return (
                <React.Fragment key={`${i.type}-${i.id}`}>
                  <div
                    className={styles.property}
                    onClick={() => {
                      setFilterIdx(idx);
                      scrollToTop();
                    }}
                  >
                    <div className={styles.wrap}>
                      <div className={styles.label}>{i.label}</div>
                      <div className={cn(styles.value, vals.length && styles.valueSelected)}>
                        {vals.length ? vals.map((v) => v.title).join(", ") : i.placeholder}
                      </div>
                    </div>
                    <div className={styles.wrapArrow}>
                      <PublicIcons name="arrowRight" width={24} height={24} />
                    </div>
                  </div>
                  {idx <= filters.length - 2 && <div className={styles.div} />}
                </React.Fragment>
              );
            })}
            <div style={{ flex: 1 }} />
            {!!Object.keys(initValues).length && (
              <Button
                color="warning"
                width="100%"
                className={styles.buttonReset}
                onClick={() => {
                  onCleanFilters();
                  onShow(false);
                  scrollToTop();
                }}
              >
                {translate("Reset filters")}
              </Button>
            )}
            <Button
              color="primary"
              width="100%"
              onClick={() => {
                onShowProducts();
                onShow(false);
                scrollToTop();
              }}
            >
              {translate("Show")}
            </Button>
          </div>
        </>
      ) : (
        <div className={cn(styles.wrapContentList)}>
          <div className={styles.div} />
          <div className={styles.checkList} data-scrollable="1">
            {filter?.values.map((i) => (
              <Checkbox
                key={`${filter.type}-${i.id}`}
                label={i.title}
                checked={filter.selected.includes(`${i.id}`)}
                onChange={(e) => onCheckedDraft(e.target.checked, filterIdx, `${i.id}`, filter)}
              />
            ))}
            <div style={{ flex: 1 }} />
          </div>
          <Button
            color="primary"
            width="100%"
            onClick={() => {
              setFilterIdx(null);
              onApplyDraft();
              scrollToTop();
            }}
            className={styles.buttonApply}
          >
            {translate("Apply")}
          </Button>
        </div>
      )}
    </div>
  );
};

const MobileFilterPrice = () => {
  const { translate } = useClientContext();
  const { categoryFiltersList, draftPrice } = useMobileFilterContext();
  const [value, setValue] = useState(draftPrice.current);

  const onDraftPrice = useCallback(
    (draft: FilterPriceRangeValue) => {
      draftPrice.current = draft;
      setValue(draft);
    },
    [draftPrice]
  );

  const minPrice = useMemo(() => {
    if (!categoryFiltersList.min_price) {
      return undefined;
    }
    const v = parseInt(`${categoryFiltersList.min_price}`);
    return !Number.isNaN(v) ? `${v}` : undefined;
  }, [categoryFiltersList.min_price]);

  const maxPrice = useMemo(() => {
    if (!categoryFiltersList.max_price) {
      return undefined;
    }
    const v = Number(`${categoryFiltersList.max_price}`);
    return !Number.isNaN(v) ? `${Math.ceil(v)}` : undefined;
  }, [categoryFiltersList.max_price]);

  return (
    <FilterPriceRange
      label={translate("Price")}
      value={value}
      min={minPrice}
      max={maxPrice}
      onChange={onDraftPrice}
      hideButtonApply
    />
  );
};

interface MobileFilterToggleProps {
  label: string;
  name: "in_stock" | "sale";
  value?: boolean;
}

const MobileFilterToggle = (props: MobileFilterToggleProps) => {
  const { name, label, value: initValue } = props;
  const { onToggleDraft } = useMobileFilterContext();
  const [value, setValue] = useState(initValue);

  const onToggle = useCallback(
    (val: boolean) => {
      setValue(val);
      onToggleDraft(name, val);
    },
    [name, onToggleDraft]
  );

  return (
    <div className={styles.MobileFilterToggle}>
      <div>{label}</div>
      <ToggleSwitch onToggle={onToggle} value={value} />
    </div>
  );
};
